import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Divider, IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import { regexForSixDigitsAndNoValueAfterDecimal } from "../utils/utils";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  fullDivider: {
    width: "100%",
  },
}));

export default function CustomizedAlertDialog(props) {
  const [value, setValue] = useState("");
  const [textField, setShowTextField] = useState(false);
  const [noOfParticipants, setNoOfParticipants] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const classes = useStyles();

  const handleChange = (event) => {
    props.sendValueToDetailSimulation(event.target.value);
    setValue(event.target.value);
    if (event.target.value === "synchronous") {
      setShowTextField(true);
      setNoOfParticipants("");
    } else if (event.target.value === "asynchronous") {
      setShowTextField(false);
      setErrorMsg("");
      props.sendNoOfParticipants(0);
    }
  };

  const handleNoOfParticipants = (e) => {
    if (
      regexForSixDigitsAndNoValueAfterDecimal.test(
        e.target.value === "" ? 0 : e.target.value
      )
    ) {
      props.sendNoOfParticipants(e.target.value);
      setNoOfParticipants(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (
      (noOfParticipants === "" || noOfParticipants === undefined) &&
      value === "synchronous"
    ) {
      setErrorMsg("Please enter no of participants");
      return;
    }
    props.handleSubmit();
  };

  useEffect(() => {
    // Reset state when dialog closes
    if (!props.show) {
      setValue("");
      setShowTextField(false);
      setNoOfParticipants("");
      setErrorMsg("");
    }
  }, [props.show]);

  return (
    <Dialog
      key={props.show ? "open" : "closed"}
      open={props.show}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {props.icon}
        <DialogTitle id="alert-dialog-slide-title" className="dialog-title">
          {props.title}
        </DialogTitle>
        <IconButton
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.fullDivider} />
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.children}
        </DialogContentText>
        {props.showRadioButtons && (
          <FormControl component="fieldset" className="custom-form-control">
            <div className="custom-radio-group">
              <div className="custom-radio-option">
                <label className="custom-radio-label">
                  <input
                    type="radio"
                    id="Synchronous"
                    name="simPlayTime"
                    value="synchronous"
                    onClick={handleChange}
                    className="custom-radio-input"
                  />
                  Synchronous
                </label>
              </div>
              <div className="custom-radio-option">
                <label className="custom-radio-label">
                  <input
                    type="radio"
                    id="Asynchronous"
                    name="simPlayTime"
                    value="asynchronous"
                    onClick={handleChange}
                    className="custom-radio-input"
                  />
                  Asynchronous
                </label>
              </div>
            </div>
          </FormControl>
        )}
        {textField && (
          <div className="form-group">
            <label> No of Participants: </label>
            <div>
              <input
                type="text"
                onChange={handleNoOfParticipants}
                value={noOfParticipants}
                required
                placeholder="No of participants"
                pattern={/^[0-9]+$/}
              />
            </div>
            <div className="msg-error">
              {(noOfParticipants === "" || noOfParticipants === undefined) &&
                errorMsg &&
                "No of participants are required."}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          className="dialog-button-submit"
          disabled={!value ? true : false}
        >
          {props.negativeButtonTitle ? props.negativeButtonTitle : "No"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
