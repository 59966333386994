import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

const AccreditationDashboardSchema = (props) => {
  const [tablesData, setTablesData] = useState([]);

  const giveEmptyTableData = () => {
    return {
      accountName: "",
      columns: [],
      formattedData: [],
    };
  };

  const transformDataForFreeTextResponseScoring = (
    accreditationClientsList,
    schema
  ) => {
    const transFormedTableData = accreditationClientsList.map((account) => {
      const { accountName, freeTextScoring } = account;

      if (freeTextScoring.length === 0) {
        return giveEmptyTableData();
      }

      const columns = Object.keys(freeTextScoring[0]).map((header, index) => ({
        dataField: header,
        text: schema[index],
        sort: false,
      }));

      let formattedData = freeTextScoring.map((row, index) => ({
        id: index + 1,
        ...row,
      }));

      return {
        accountName,
        columns,
        formattedData,
      };
    });

    return transFormedTableData;
  };

  const transformDataForAccreditation = (accreditationClientsList) => {
    const transFormedTableData = accreditationClientsList.map((account) => {
      const { accountName, accreditationRows, totalPositives, totalNegatives } =
        account;

      if (accreditationRows.length === 0) {
        return giveEmptyTableData();
      }

      const columns = Object.keys(accreditationRows[0]).map((header) => ({
        dataField: header,
        text: header,
        sort: false,
      }));

      //transforming rows to set "" when 0
      const transformRow = (row) => {
        const transformedRow = { ...row };
        Object.keys(transformedRow).forEach((key) => {
          if (transformedRow[key] === "0" || transformedRow[key] === 0) {
            transformedRow[key] = "";
          }
        });
        return transformedRow;
      };

      let formattedData = accreditationRows.map((row, index) => ({
        id: index + 1,
        ...transformRow(row),
      }));

      formattedData.push({
        id: accreditationRows.length + 1,
        Name: "",
        [props.schema[1]]: "",
        customClass: "increased-height-row",
      });
      formattedData.push({
        id: accreditationRows.length + 2,
        Name: "Positives",
        [props.schema[1]]: totalPositives,
      });
      formattedData.push({
        id: accreditationRows.length + 3,
        Name: "Negatives",
        [props.schema[1]]: totalNegatives,
      });

      return {
        accountName,
        columns,
        formattedData,
      };
    });
    return transFormedTableData;
  };

  useEffect(() => {
    const reportData = props.accreditationClientsList;
    const schema = props.schema;
    if (reportData) {
      setTablesData(
        props.report == 1
          ? transformDataForAccreditation(reportData)
          : transformDataForFreeTextResponseScoring(reportData, schema)
      );
    }
  }, [props.accreditationClientsList]);

  const allTablesHaveNoColumns = tablesData.every(
    (table) => table.columns.length === 0
  );

  const rowClasses = (row) => {
    return row.customClass || "";
  };

  return (
    <div className="card-body mt-5">
      {allTablesHaveNoColumns ? (
        <div className="accreditation-msg">No Data Exists</div>
      ) : (
        tablesData.map((table, index) => {
          if (table.columns.length === 0) {
            return <div key={index}></div>;
          }

          return (
            <div key={index} className="table-container">
              <h4 className="mb-4">
                <span className="account-name-heading">Account Name: </span>
                <span className="account-name-value ml-2">
                  {table.accountName}
                </span>
              </h4>
              <div className="table-responsive data-table-wrapper table-heading mb-3 white_space_preserve">
                <BootstrapTable
                  keyField="id"
                  data={table.formattedData}
                  columns={table.columns}
                  rowClasses={rowClasses}
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default AccreditationDashboardSchema;
