import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/loader";
import Layout from "../../../layout/Layout";
import AppService from "../../../services/app.service";
import Select from "react-select";
import {
  accreditationTypeReports,
  base64ToExcel,
  findAccountByValue,
  getMultiSelectOptions,
  handleMultiSelectChange,
  selectAllAccounts,
  selectAllSimulations,
  selectAllStandards,
} from "../../../utils/utils";
import { DateRangePicker } from "react-date-range";
import { InputRanges } from "../../../constants/dateRanges";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import auth from "../../../services/auth";
import AccreditationDashboardSchema from "./accreditation-dashboard-schema";
import { ACTIVE, INACTIVE } from "../../../utils/constants";
import { formatDateToString } from "../../../utils/dateUtils";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Tooltip from "@material-ui/core/Tooltip";
import AlertDialogSlide from "../../../components/deleteModal";
import { makeStyles } from "@material-ui/core/styles";

const initialState = {
  accreditationClient: [],
  simulations: [],
  standards: [],
  startDate: new Date(),
  endDate: new Date(),
  filterBy: { activeFilter: true, inActiveFilter: false },
  accountFilteration: "",
  accountId: [],
  timeZone: "",
  accDetail: [],
  simulationsByAccount: [],
  simulationsListByAccount: [],
};

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
    marginLeft: 0,
  }),
};

let superAdmin = { value: 0, label: "Super Admin", active: ACTIVE };

const AccreditationDashboard = (props) => {
  const useStylesForMargin = makeStyles({
    customMargin: {
      marginLeft: "0px !important",
    },
  })();

  const [formState, setFormState] = useState(initialState);
  const [isShowModel, setIsShowModel] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [accreditationResponseData, setAccreditationResponseData] = useState({
    reportNameValue: "",
    schema: [],
    accreditationClientListData: [],
    excelReport: "",
  });
  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [accreditationClientsList, setAccreditationClientsList] = useState([]);
  const [simulationList, setSimulationsList] = useState([]);
  const [standardsList, setStandardsList] = useState([]);
  const { startDate, endDate } = formState;
  const [expanded, setExpanded] = useState(true);
  const [selectReport, setSelectReport] = useState(1);

  const selectionRange = {
    startDate,
    endDate,
  };

  const loadData = async () => {
    const accId = auth.getUserDetails();
    setFormState((prevState) => ({
      ...prevState,
      accountId: accId?.accId,
    }));
    const accounts = await AppService.getAll(
      "accounts/getAccreditationClientAccounts"
    );
    const accountList = accounts.data.map((account) => {
      return {
        value: account.accId,
        label: account.accName,
        active: account.active,
      };
    });
    const accDetail = findAccountByValue(accountList, accId?.accId);
    const simulations = await AppService.getAll("sims/list/external");
    const simulationList = simulations.data.map((simDoc) => {
      return { value: simDoc.simCode, label: simDoc.label };
    });
    const standards = await AppService.getAll("allStandards/getAll");
    const standardsList = standards?.data?.data?.map(
      (standard) => {
        return {
          value: standard.uuid,
          label: standard.label,
          uuid: standard.uuid,
        };
      }
    );
    const simulationsByAccount = await AppService.getAll(
      `sims/get-simulations-by-account/${accId?.accId}`
    );
    const simulationsListByAccount = simulationsByAccount.data.map((sim) => {
      return { value: sim.simCode, label: sim.label };
    });

    setAccreditationClientsList(accountList);
    setSimulationsList(simulationList);
    setStandardsList(standardsList);
    setFormState((prevState) => ({
      ...prevState,
      simulationsListByAccount: simulationsListByAccount,
      accDetail: accDetail,
    }));
    setIsLoader(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleMultiSimulationChange = (value, action) => {
    const list = handleMultiSelectChange(
      value,
      action,
      simulationList,
      formState.simulations,
      selectAllSimulations
    );
    setFormState((prevState) => ({ ...prevState, simulations: list }));
  };

  const handleMultiSimulationChangeByAccount = (value, action) => {
    const list = handleMultiSelectChange(
      value,
      action,
      formState.simulationsListByAccount,
      formState.simulationsByAccount,
      selectAllSimulations
    );
    setFormState((prevState) => ({ ...prevState, simulationsByAccount: list }));
  };

  const handleMultiStandardChange = (value, action) => {
    const list = handleMultiSelectChange(
      value,
      action,
      standardsList,
      formState.standards,
      selectAllSimulations
    );
    setFormState((prevState) => ({ ...prevState, standards: list }));
  };

  const handleDateRangeChanged = async (ranges) => {
    setFormState((prevState) => ({
      ...prevState,
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    }));
  };

  const handleAccountFilter = (e, checkBoxName) => {
    const isChecked = e.target.checked || false;

    setFormState((prevState) => ({
      ...prevState,
      filterBy: { ...prevState.filterBy, [checkBoxName]: isChecked },
      accountFilteration: "",
    }));
  };

  const handleAccountChange = (selectedOptions, actionMeta) => {
    let list;
    let removedAccount;
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option") {
      if (option.value === selectAllAccounts.value) {
        const filteredAccounts = filterAccountsByStatus();
        setFormState((prevState) => ({
          ...prevState,
          accreditationClient: [
            ...formState.accreditationClient,
            ...filteredAccounts,
          ],
        }));
      } else {
        list = [...formState.accreditationClient];
        list.push(option);

        setFormState((prevState) => ({
          ...prevState,
          accreditationClient: list,
        }));
      }
    } else if (action === "remove-value") {
      list = formState.accreditationClient.filter(
        ({ value }) => value !== removedValue.value
      );
      removedAccount = formState.accreditationClient?.filter(
        ({ value }) => value === removedValue.value
      );
      setFormState((prevState) => ({
        ...prevState,
        accreditationClient: list,
      }));
    } else if (action === "clear") {
      setFormState((prevState) => ({
        ...prevState,
        accreditationClient: [],
      }));
    }
    setFormState((prevState) => ({
      ...prevState,
    }));

    setFormState((prevState) => ({
      ...prevState,
      accreditationClient: [...new Set(prevState.accreditationClient)],
    }));
  };

  const filterAccountsByStatus = () => {
    let filteredAccounts;
    if (formState.filterBy.activeFilter && formState.filterBy.inActiveFilter) {
      return [superAdmin, ...accreditationClientsList];
    } else if (formState.filterBy.activeFilter) {
      filteredAccounts = accreditationClientsList.filter(
        (obj) => obj.active === ACTIVE
      );
      filteredAccounts.unshift(superAdmin);
      return filteredAccounts;
    } else if (formState.filterBy.inActiveFilter) {
      filteredAccounts = accreditationClientsList.filter(
        (obj) => obj.active === INACTIVE
      );
      return filteredAccounts;
    }
  };

  const getAccountOptions = () => {
    let accountOptions = [...formState.accreditationClient];

    if (formState.filterBy.activeFilter) {
      const activeAccounts = accreditationClientsList.filter(
        (obj) => obj.active === ACTIVE
      );
      accountOptions = [superAdmin, ...activeAccounts, ...accountOptions];
    }
    if (formState.filterBy.inActiveFilter) {
      const inActiveAccounts = accreditationClientsList.filter(
        (obj) => obj.active === INACTIVE
      );
      accountOptions = [...accountOptions, ...inActiveAccounts];
    }
    accountOptions = [...new Set(accountOptions)];
    if (accountOptions?.length > formState.accreditationClient?.length) {
      accountOptions = [selectAllAccounts, ...accountOptions];
    }
    return accountOptions;
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeForShowingModal = (isShow) => setIsShowModel(isShow);

  const generateAccreditationReport = async (e) => {
    if (formState.accountId != 0 && formState.accDetail == null) {
      handleChangeForShowingModal(true);
      return;
    }
    e.preventDefault();
    const filterCheck =
      !formState.filterBy.activeFilter && !formState.filterBy.inActiveFilter;
    setFormState((prevState) => ({
      ...prevState,
      accountFilteration: filterCheck && "Please select at least one filter",
    }));
    if (simpleValidator.current.allValid() && !filterCheck) {
      setIsLoader(true);
      const accreditationCriteria = {
        report: `${accreditationTypeReports[selectReport - 1].label} Report`,
        accounts:
          formState.accountId === 0
            ? formState.accreditationClient
            : formState.accDetail === null
            ? []
            : [formState.accDetail],
        simulations:
          formState.accountId === 0
            ? formState.simulations
            : formState.simulationsByAccount,
        standards: formState.standards,
        startDate: formatDateToString(formState.startDate),
        endDate: formatDateToString(formState.endDate),
        activeInactiveFilters: [
          formState.filterBy.activeFilter ? "Active" : "",
          formState.filterBy.inActiveFilter ? "InActive" : "",
        ],
      };

      const reportUrl =
        selectReport === 1
          ? "accreditationDashboard/accreditationReport"
          : "accreditationDashboard/freeTextResponseScoring";

      const report = await AppService.downloadFromStream(
        accreditationCriteria,
        reportUrl,
        "POST",
        "blob",
        false
      )
        .then((response) => {
          if (selectReport === 1) {
            setAccreditationResponseData({
              reportNameValue: "1",
              schema: response?.data.accreditationData.schemas,
              accreditationClientListData:
                response?.data.accreditationData.accreditationClientList,
              excelReport: response.data.excelFileBase64,
            });
          } else {
            setAccreditationResponseData({
              reportNameValue: "2",
              schema:
                response?.data?.freeTextResponseScoringReportData?.schemas,
              accreditationClientListData:
                response?.data?.freeTextResponseScoringReportData
                  ?.freeTextResponseScoringEntries,
              excelReport: response.data.excelFileBase64,
            });
          }
          setIsLoader(false);
        })
        .catch((error) => {
          console.debug(error);
        });
      setIsLoader(false);
    } else {
      setExpanded(true);
      simpleValidator.current.showMessages();
    }
  };

  const generateExcel = (name) => {
    base64ToExcel(accreditationResponseData.excelReport, name);
  };

  const handleReportChange = (event) => {
    setSelectReport(event.value);
  };

  return (
    <div>
      <Layout
        title="Accreditation Dashboard Management"
        history={props.history}
        subTitle={` Accreditation Dashboard`}
        activeAccreditationDashboard={true}
        showBreadCrumb={false}
      >
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center btn-styling">
                    <h3 className="sub-heading">Accreditation Dashboard</h3>
                    <Tooltip title={expanded ? "Collapse" : "Expand"}>
                      <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" noValidate={true}>
                      <Collapse in={expanded}>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label label">
                            Select Accreditation Type
                          </label>
                          <div className="col-sm-9">
                            <Select
                              name="reportType"
                              className="fields-font"
                              defaultValue={accreditationTypeReports[0]}
                              options={accreditationTypeReports}
                              onChange={handleReportChange}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        {formState.accountId === 0 && (
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label required label">
                              Select Accounts
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="accreditationClient"
                                isMulti={true}
                                value={formState.accreditationClient}
                                className="basic-multi-select fields-font"
                                options={getAccountOptions()}
                                onChange={handleAccountChange}
                                styles={customStyles}
                              />
                              {simpleValidator.current.message(
                                "Accounts",
                                formState.accreditationClient,
                                "required"
                              )}
                            </div>
                          </div>
                        )}
                        {formState.accountId === 0 ? (
                          <div className="form-group row">
                            <label
                              className={
                                "col-sm-3 form-control-label required content-body"
                              }
                            >
                              Select Simulations
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="simulationsList"
                                isMulti={true}
                                options={getMultiSelectOptions(
                                  simulationList,
                                  formState.simulations,
                                  selectAllSimulations
                                )}
                                value={formState.simulations}
                                className="basic-multi-select fields-font"
                                onChange={handleMultiSimulationChange}
                                styles={customStyles}
                              />
                              {simpleValidator.current.message(
                                "Simulations",
                                formState.simulations,
                                "required"
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="form-group row">
                            <label
                              className={
                                "col-sm-3 form-control-label required content-body"
                              }
                            >
                              Select Simulations
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="simulationsList"
                                isMulti={true}
                                options={getMultiSelectOptions(
                                  formState.simulationsListByAccount,
                                  formState.simulationsByAccount,
                                  selectAllSimulations
                                )}
                                value={formState.simulationsByAccount}
                                className="basic-multi-select fields-font"
                                onChange={handleMultiSimulationChangeByAccount}
                                styles={customStyles}
                              />
                              {simpleValidator.current.message(
                                "Simulations",
                                formState.simulationsByAccount,
                                "required"
                              )}
                            </div>
                          </div>
                        )}
                        <div className="form-group row">
                          <label
                            className={
                              "col-sm-3 form-control-label  content-body"
                            }
                          >
                            Select Standards
                          </label>
                          <div className="col-sm-9">
                            <Select
                              name="standardsList"
                              isMulti={true}
                              options={getMultiSelectOptions(
                                standardsList,
                                formState.standards,
                                selectAllStandards
                              )}
                              value={formState.standards}
                              className="basic-multi-select fields-font"
                              onChange={handleMultiStandardChange}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        {formState.accountId === 0 && (
                          <div className="form-group row">
                            <label
                              className={
                                "col-sm-3 form-control-label required content-body"
                              }
                            >
                              Filter By
                            </label>
                            <div className="col-sm-9">
                              <FormGroup row>
                                <FormControlLabel
                                  className={`${useStylesForMargin.customMargin} MuiFormControlLabel-root`}
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={formState.filterBy.activeFilter}
                                      onChange={(e) =>
                                        handleAccountFilter(e, "activeFilter")
                                      }
                                    />
                                  }
                                  label="Active"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        formState.filterBy.inActiveFilter
                                      }
                                      onChange={(e) =>
                                        handleAccountFilter(e, "inActiveFilter")
                                      }
                                    />
                                  }
                                  label="InActive"
                                />
                              </FormGroup>
                              {formState.accountFilteration && (
                                <p className="error-msg m-0 p-0 fields-font">
                                  {formState.accountFilteration}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="row" style={{ marginTop: 18 }}>
                          <label className="col-3 form-control-label mt-2 content-body required label">
                            Date Range
                          </label>
                          <div className="col-9">
                            <DateRangePicker
                              ranges={[selectionRange]}
                              onChange={handleDateRangeChanged}
                              maxDate={new Date()}
                              inputRanges={InputRanges}
                            />
                          </div>
                        </div>
                      </Collapse>
                      {accreditationResponseData.accreditationClientListData
                        ?.length > 0 && (
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-submit float-right buttons"
                            onClick={() => {
                              generateExcel(
                                accreditationResponseData.reportNameValue == 1
                                  ? "AccreditationReport.xls"
                                  : "FreeTextResponseScoringReport.xls"
                              );
                            }}
                          >
                            Export to Excel
                          </button>
                        </div>
                      )}
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-submit float-right buttons"
                          onClick={generateAccreditationReport}
                        >
                          {selectReport === 1
                            ? "Generate Accreditation Dashboard"
                            : "Generate Free Text Response Scoring Dashboard"}
                        </button>
                      </div>
                      {accreditationResponseData.reportNameValue && (
                        <AccreditationDashboardSchema
                          schema={accreditationResponseData.schema}
                          accreditationClientsList={
                            accreditationResponseData.accreditationClientListData
                          }
                          report={accreditationResponseData.reportNameValue}
                        />
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AlertDialogSlide
          show={isShowModel}
          handleNo={() => {
            handleChangeForShowingModal(false);
          }}
          title={"Accreditation Dashboard Report"}
          negativeButtonTitle="OK"
          icon="true"
        >
          <p className="fields-font">
            This functionality is not available for non-accreditation clients.
            Please contact the administrator for further information at &nbsp;
            <span className="color-blue">cx@schoolsims.com.</span>
          </p>
        </AlertDialogSlide>
      </Layout>
      {isLoader && (
        <div className="overlay">
          <Loader loading={isLoader} />
        </div>
      )}
    </div>
  );
};

export default AccreditationDashboard;
